$( document ).ready(function() {
    // check if Piwik is defined
    if (typeof _paq !== "undefined" ) {
        var src;
        /**
         * Send custom Piwik trackEvent with category "Audio", action "Play" and name to be the src
         * of the playing audio element.
         * Now there are two ways of defining the source of an audio element in HTML:
         * 1. in the src attribute of the <audio> element
         * 2. in the src attribute of a <source> child element of <audio>, e.g. <source src="3.mp3">
         */
        $("audio").bind("playing", function () {
            if (typeof $(this).attr("src") !== "undefined") {
                // audio src is defined in the src attribute of the <audio> element
                src = $(this).attr("src");
            } else {
                // audio src is defined in the src attribute of a <source> child element of <audio>
                src = $(this).find("source:first").attr("src");
            }
            // send Piwik event
            _paq.push(["trackEvent", "Audio", "Play", src]);
        });
    }
});