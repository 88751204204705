$('body').bind('fx-ready', function(event) {
    $(event.target).find('.js-gba-entity-list').each(function() {
        var entityIdToLinkMap = {};
        var activeLinkIds = [];
        var list = $(this);
        var listContext = list.closest('.js-list-context');
        if (listContext.length) {
            var listContextName = listContext.metadata().listContextName.toString();
        } else {
            var listContextName = null;
        }

        list.find('table').each(function() {
            var table = $(this);
            if (!table.parent().is('.gba-entity-list__body')) table.wrap('<div class="gba-entity-list__body">');
        });

        list.find('.pagination a, thead a, .fx-filter a').each(function() {
            var link = $(this);
            var url = link.attr('href');
            var urlEncoded = encodeURIComponent(url);
            link.click(function() {
                list.trigger({
                    type: 'fx-load-url',
                    url: link.attr('href'),
                    onAfter: function() {
                        var state = {list: urlEncoded};
                        if (listContextName)
                            state.listContext = listContextName
                        appState.update(state);
                    }
                });
                return false;
            });
        });

        list.find('.fx-compact-link').each(function() {
            var span = $(this);
            var tr = span.closest('tr');
            var mode = span.metadata().mode;
            var url = span.metadata().link;
            var entityId = url.match(/\d+/)[0];
            var nonAjaxLink = tr.find('a');
            var strategy = compactLinkStrategies[mode];
            var windowWidth = $(window).width();

            entityIdToLinkMap[entityId] = nonAjaxLink;

            nonAjaxLink.click(function() {
                // AJAX-Lade und zeige Inhalte inline nur ab mittleren Bildschirmen
                if (windowWidth > 480) {
                    nonAjaxLink.blur();
                    if (tr.data('active')) {
                        activeLinkIds = $.grep(activeLinkIds, function(id) {
                            return (id != entityId);
                        });
                        tr.data('active', false);
                        strategy.hide(tr);
                        appState.update({details: activeLinkIds.join(':')});
                    } else {
                        activeLinkIds.push(entityId);
                        tr.data('active', true);
                        var state = {details: activeLinkIds.join(':')};
                        if (listContextName)
                            state.listContext = listContextName
                        appState.update(state);
                        strategy.show(tr, url, nonAjaxLink);
                    }
                    return false;
                } else {
                    // Öffne Detailseite auf kleinen Bildschirmen
                    window.location.href = nonAjaxLink.attr('href');
                }
            });
        });

        appState.register(function(state, initially) {
            if (!listContextName || state.listContext == listContextName) {
                if (state.list) {
                    list.trigger({
                        type: 'fx-load-url',
                        url: decodeURIComponent(state.list)
                    });
                } else {
                    list.trigger({
                        type: 'fx-load-initial-url'
                    });
                }
                if (state.details) {
                    var ids = state.details.split(':');
                    $.each(ids, function() {
                        var id = this;
                        if (entityIdToLinkMap[id]) {
                            var link = entityIdToLinkMap[id];
                            if (!link.closest('tr').data('active')) {
                                link.click();
                                if (initially)
                                    $(window).scrollTop(link.closest('.fx-entity-list').offset().top - 100);
                            }
                        }
                    });
                    $.each(activeLinkIds, function() {
                        var id = this.toString();
                        if ($.inArray(id, ids) == -1) {
                            if (entityIdToLinkMap[id]) {
                                var link = entityIdToLinkMap[id];
                                if (link.closest('tr').data('active')) {
                                    link.click();
                                }
                            }
                        }
                    });
                } else if (!state.details && activeLinkIds.length) {
                    $.each(activeLinkIds, function() {
                        var link = entityIdToLinkMap[this];
                        link.click();
                    });
                }
            }
        }, event.target);
    });
});

var compactLinkStrategies = {

    row: {
        hide: function(tr) {
            tr.removeClass('active');
            tr.addClass('inactive');
            tr.next().hide();
        },
        show: function(tr, url, nonAjaxLink) {
            tr.removeClass('inactive');
            tr.addClass('active');
            if (tr.data('compact-loaded')) {
                tr.next().show();
            } else {
                var numberOfCols = tr.find('td').length;
                var stage = tr.after('<tr class="loading"><td colspan="' + numberOfCols + '" class="compact"></td></tr>').next().children(':first');

                stage.load(url, null, function() {
                    stage.trigger('fx-ready');
                    tr.next().removeClass('loading');
                    tr.data('compact-loaded', true);
                    stage.show('slow');
                });
            }
        }
    },

    overlay: {
        hide: function(tr) {
            var list = tr.closest('.fx-entity-list');
            var listAndSiblings = list.parent().children();
            var overlay = tr.data('overlay');
            var wrapper = tr.closest('.fx-compact-overlay-wrapper');

            listAndSiblings.show();
            overlay.css({
                position: 'absolute',
                left: '100%'
            });
            wrapper.scrollTo(overlay);
            wrapper.scrollTo(list,  1000, {
                onAfter: function() {
                    overlay.hide();
                    wrapper.css('height', 'auto');
                    tr.find('a:first').focus();
                }
            });
        },
        show: function(tr, url, nonAjaxLink) {
            var scrollingFinished = false;
            var loadingFinished = false;
            var list = tr.closest('.fx-entity-list');
            var listAndSiblings = list.parent().children();
            var overlay;
            var wrapper;

            var hideList = function() {
                if (scrollingFinished && loadingFinished) {
                    listAndSiblings.not(overlay).hide();
                    overlay.css({
                        position: 'relative',
                        left: 0
                    });
                    overlay.find('a:first').focus();
                }
            };

            if (tr.data('overlay')) {
                overlay = tr.data('overlay');
                wrapper = tr.closest('.fx-compact-overlay-wrapper');
                wrapper.height(overlay.data('height') + 'px');
                loadingFinished = true;
            } else {
                if (!list.parent().is('.fx-compact-overlay-wrapper')) {
                    var wrapper = list.before('<div class="fx-compact-overlay-wrapper compact-overlay-wrapper"></div>').prev();
                    wrapper.append(wrapper.nextAll());
                } else {
                    wrapper = tr.closest('.fx-compact-overlay-wrapper');
                }

                overlay = list.after('<div class="compact-overlay"><div class="compact-overlay-content"><a href="javascript:void(0)" class="compact-overlay-close fx-compact-overlay-close">zurück zur Liste</a><div class="fx-compact-overlay-stage compact-overlay-stage loading"></div></div></div>').next();
                tr.data('overlay', overlay);

                var closeButton = overlay.find('.fx-compact-overlay-close');
                var stage = overlay.find('.fx-compact-overlay-stage');

                stage.load(url, null, function() {
                    loadingFinished = true;
                    stage.removeClass('loading');
                    stage.trigger('fx-ready');
                    overlay.css('position', 'relative');
                    var height = overlay.outerHeight(true);
                    overlay.data('height', height);
                    overlay.css('position', 'absolute');
                    if (wrapper.height() < height)
                        wrapper.height(height + 'px');
                    hideList();
                });

                closeButton.click(function() {
                    nonAjaxLink.click();
                });
            }

            overlay.show();
            wrapper.scrollTo(overlay, 1000, {
                onAfter: function() {
                    scrollingFinished = true;
                    hideList();
                }
            });
        }
    }

};
