(function($) {
    /**
     * a11y Enhancements für die mobile Ansicht mit Themenbereichs-Unternavigation
     * als Akkordeon
     *
     * Auf der Themenübersicht nutzen wir für die Subnavigation der Themenbereiche
     * das visuelle Klammer-Pattern. Zusätzlich setzen wir appendAround ein, damit
     * die Navigation auf kleinen Bildschirmen vor der A-Z Filterliste erscheint.
     *
     * Um hier Platz zu sparen, wird aber nicht einfach die gesamte Navigation im DOM
     * umgehängt, sondern nur die <ul> in ein vorbereitetes (eingeklapptes) Akkordeon
     * eingefügt.
     *
     * Da wir aus a11y Gründen trotzdem einen globalen Skiplink unterstützen wollen,
     * (Hintergrund: Desktop-/Tastatur-Nutzer mit Seitenzoom bekommen ab 3 Zoomstufen
     * sozusagen das mobile Layout) müssen wir mit JS ein wenig "hinterher putzen".
     */

    'use strict';

    /**
     * Erzeuge den "gba" namespace falls nicht bereits vorhanden, und erzeuge einen
     * themen sub-namespace.
     */
    window.gba = window.gba || {};
    window.gba.themen = window.gba.themen || {};

    var mobileSubNavId = '#themenbereiche-subnav';
    var themenSubNav = $(mobileSubNavId);

    // Steige aus, falls wir nicht auf der Themenübersicht-Seite sind
    if (themenSubNav.length) {
        window.gba.themen.isUebersicht = true;
    } else {
        return;
    }

    var $subnavSkiplink = $('.js-sub-nav-skiplink'); // finde initial den Desktop-Ansicht-optimierten Sprunglink
    var desktopSubNavId = $subnavSkiplink.attr('href'); // speichere sein Sprunglinkziel (die #ID)

    function updateSkiplink() {
        if(im.lessThan('screen-md')) {
            $subnavSkiplink.attr('href', mobileSubNavId);
        } else {
            $subnavSkiplink.attr('href', desktopSubNavId);
        }
    }

    $(window).on('load resize', function() {
        debounce(updateSkiplink(), 100);
    });
})(jQuery);
