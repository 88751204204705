/**
 * Skript, das "DOM Updates fertig!"-Events einzelner Komponenten wie z. B. Akkodeons kennt und ein allgemeines
 * "Alles fertig!" Event wirft, sobald die letzte registrierte Komponente Vollzug meldet.
 */
(function() {
    // Alle Eventnamen hier einzeln im Array aufführen.
    // TODO: Interface für registerEvent() einführen, statt hier Strings zu pflegen?
    var MODULES_READY_EVENTS = [
        'wf.accordions.mounted',
    ];

    MODULES_READY_EVENTS.forEach(function(event, index) {
        window.addEventListener(event, function() {
            MODULES_READY_EVENTS.splice(index, 1);

            if (MODULES_READY_EVENTS.length === 0) {
                var ALL_DOMCHANGES_COMPLETED = document.createEvent('Event');

                ALL_DOMCHANGES_COMPLETED.initEvent('gba.domchanges.completed', true, true);
                window.dispatchEvent(ALL_DOMCHANGES_COMPLETED);
            }
        });
    });
})();
