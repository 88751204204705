$('body').bind('fx-ready', function(event) {
	$(event.target).find('.hoverable, .all-hoverable *').hover(function() {
		var hoverable = $(this);
		var additionalClasses = [];
		$.each(hoverable.attr('class').split(' '), function() {
			var originalClass = this;
			var additionalClass = originalClass + '-hover';
			hoverable.addClass(additionalClass);
			additionalClasses.push(additionalClass);
		});
		hoverable.data('hoverableAdditionalClasses', additionalClasses);
		hoverable.addClass('hover');
	}, function() {
		var hoverable = $(this);
		var additionalClasses = hoverable.data('hoverableAdditionalClasses');
		hoverable.removeClass('hover');
		if(additionalClasses){
			$.each(additionalClasses, function() {
				hoverable.removeClass(this.toString());
			});
		}
	});
});