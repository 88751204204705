$('body').bind('fx-ready', function(event) {
    $(event.target).find('.targeting-list').each(function() {
        $(this).find('li').addClass('fx-targeting targeting-highlight hoverable');
        $(this).find('li:first').addClass('targeting-list-first');
    });

    var controls;

    var colorbox = $(event.target).find('a.zoom').colorbox({
        'photo': true,
        'rel': 'content',
        'maxHeight': '90%',
        'maxWidth': '90%',
        'title': '',
        'previous': '<a class href="#n">Vorheriges</a>',
        'next': '<a class href="#n">Nächstes</a>',
        'close': 'Schließen (Esc)',
        'onOpen': function() {
            controls = $('a, :input');
            var dialogControls = $('#cboxNext, #cboxPrevious, #cboxClose');
            var otherControls = controls.not(dialogControls);
            otherControls.attr('tabIndex', -1);
        },
        'onClosed': function() {
            controls.removeAttr('tabIndex');
        }
    });

    $(event.target).find('.form-nutzenbewertungverfahrensuche').each(function() {
        var form = $(this);
        form.find('input[type="reset"]').click(function() {
            form.find('select').val('0');
            return false;
        });
    });
});

$('body').bind('fx-load-url', function(event) {
    if (document.location.href == event.url)
        return false;

    $('body').trigger('fx-remove');
    document.location.replace(event.url);
});
