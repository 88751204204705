(function($) {

    // Wir müssen die alte Hash-Funktionalität nur für Seiten abfangen, die wir umgebaut haben;
    // das sind exakt Richtlinien- und Richtlinie-Anlage-Detailseiten sowie Detailseiten von Nutzenbewertungsverfahren
    if (window.location.href.indexOf('/richtlinien/') === -1 && window.location.href.indexOf('/nutzenbewertung/') === -1 || !window.location.hash) {
        return;
    }

    var pathname = window.location.pathname;
    var hostname = window.location.hostname;
    var hashSubstrings = explodeStringAtSlash(window.location.hash);

    // Wir behandeln nur unsere eigene, alte Tabs-Funktionalität
    if (hashSubstrings[0].indexOf('#tab') === -1) {
        return;
    }

    if (hashSubstrings.length === 2) {
        // Fall 1: Behandle die alte Möglichkeit, ein bestimmtes Tab auf einer Nutzenbewertungsverfahren-Detailseite
        // via URL hash bei Aufruf der Seite zu aktivieren
        // Durch die neuen Akkordeon/Tabs-Plugins ist hier nur tab/ aus der alten URL zu entfernen
        // URL-Beispiel: …/nutzenbewertung/410/#tab/beschluesse wird zu …/nutzenbewertung/410/#beschluesse
        if (window.location.href.indexOf('/nutzenbewertung/') !== -1) {
            window.location.hash = hashSubstrings[1];
        }

        // Fall 2: Behandle die alte Möglichkeit, ein bestimmtes Tab (z. B. mit einer Liste von Entitäten wie Beschlüsse
        // oder Historie) auf einer Detailseite (z. B. Rili-Detailseite) direkt anzusteuern
        // Durch die neuen Unterseiten ist hier ist schlussendlich nur /#tab/ aus der alten URL zu entfernen.
        // URL-Beispiel: …/richtlinien/3/#tab/beschluesse/ wird zu …/richtlinien/3/beschluesse/
        // Ein Sonderfall: die Inhalte des ehemaligen Tabs "Weitere Informationen" stehen jetzt direkt auf der Detailseite;
        // die /#tab/weitere-informationen-Route muss daher von der Umleitung ausgenommen werden, um keine 404 zu erzeugen
        if (window.location.href.indexOf('/richtlinien/') !== -1 && hashSubstrings[1].indexOf('weitere-informationen') === -1) {
            window.location.assign('https://' + hostname + pathname + hashSubstrings[1]);
        }
    }

    // Fall 3: Behandle die Möglichkeit, in einem bestimmten Tab, welches eine Liste von Entitäten enthält,
    // direkt die Detail-Informationen einer dieser Entitäten anzuzeigen.
    // Durch die neue Seitenhierarchie muss hierzu die "echte" Detailseite der Entität geöffnet werden, anstatt sie
    // wie vorher möglich im (visuellen) Kontext ihrer "Elternseite" zu zeigen.
    // URL-Beispiel: …/richtlinien/3/#tab/beschluesse/details/3252/ wird zu …/beschluesse/3252/
    if (hashSubstrings.length === 4 && hashSubstrings[2].indexOf('details') !== -1) {
        window.location.assign('https://' + hostname + '/' + hashSubstrings[1] + '/' + hashSubstrings[3]);
    }

    /**
     *
     * @param string String
     * @returns {Array}
     */
    function explodeStringAtSlash(string) {
        var substrings = [];

        $.each(string.split('/'), function() {
            // filtere Leerstrings aus
            if (this.length) {
                substrings.push(this);
            }
        });

        return substrings;
    }

})(jQuery);