(function($) {

    /**
     * Liste der Vendors, deren iframes automatische Höhe habe können (setzt Einbindung und Aufruf eines Skripts
     * seitens des Vendors voraus – siehe https://github.com/Sly777/Iframe-Height-Jquery-Plugin).
     *
     * Der angegebene String muss Teil der src-URL des iframes sein. Da diese iframes z. T. in wfDynamic durch Kunden
     * eingebettet werden und unterschiedliche URLs haben können, nutzen wir defensiv partielle Strings.
     *
     * Bsp.: "perbit" stellvertretend für "https://g-ba-jobs.perbit-job.de/?list=2"
     *
     * @type {string[]}
     */
    var sources = ['perbit', 'ais'];

    var options = {
        resizeMaxTry: 10,
        minimumHeight: 150,
        defaultHeight: 150,
    };

    /**
     * NodeList.forEach Polyfill
     * @src https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
     */
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    /**
     *
     * @param $iframes
     * @param source
     */
    function initIframeHeight($iframes, source) {
        $iframes.each(function(index, iframe) {
            var $iframe = $(iframe);
            var iframeUniqueId = source + '-iframe-' + index;

            // Exit early (behandle nur iframes, die wir noch nicht "gesehen" haben)
            if ($iframe.attr('id') === iframeUniqueId) {
                return;
            }

            $iframe.attr('id', iframeUniqueId);

            // checke, ob das iframe als parent Kontext ein Akkordeon hat und initial versteckt sein könnte
            // TODO: für Tabs und einklappbarer-Ausschnitt erweitern; dazu müssen diese Komponenten Events feuern,
            //  die wir dann in gba.domchanges-manager.js eintragen können.
            var $disclosureParent = $iframe.closest('.js-accordion-group', '.js-disclosure', '[data-atabs]');

            // falls nicht, rufe das Skript sofort…
            if ($disclosureParent.length === 0) {
                $iframe.iframeHeight(options);

            // …ansonsten registriere für "gba.domchanges.completed" einen MutationObserver, der das iframe parent panel und seine
            // a11y-Attribute beobachtet, die zu diesem Zeitpunkt sicher via JS erzeugt worden sind.
            } else {
                window.addEventListener('gba.domchanges.completed', function() {
                    var observer = new MutationObserver(function(mutationsList, observer) {
                        mutationsList.forEach(function(mutation) {
                            $iframe.iframeHeight(options);
                            observer.disconnect(); // Skript muss pro iframe nur einmalig ausgeführt werden!
                        });
                    });

                    var $observationTarget = $iframe.closest('.js-accordion__panel', '[data-atabs-panel]');

                    var isOpenAccordionPanel = $observationTarget.hasClass('js-accordion__panel') && $observationTarget.attr('aria-hidden') === 'false';
                    var isOpenTabPanel = $observationTarget.attr('[data-atabs-panel]') && !$observationTarget.attr('hidden');

                    if (isOpenAccordionPanel || isOpenTabPanel) {
                        $iframe.iframeHeight(options);
                    } else {
                        observer.observe($observationTarget.get(0), {
                            attributes: true,
                            attributeFilter: ['aria-hidden', 'hidden'],
                        });
                    }
                });
            }

        });
    }

    var $iframeSources = $(sources);

    $iframeSources.each(function(index, source) {
        var $iframes = $('iframe[src*="' + source + '"]'); // sammle alle iframes eines vendors

        initIframeHeight($iframes, source);
    });

})(jQuery);
