/**
 * Vanilla JS utility für $(document).ready() Äquivalent.
 *
 * @param fn Function to execute when the document is ready
 * @returns {*}
 */
function documentReady(fn) {
    // Sanity check
    if (typeof fn !== 'function') return;

    // If the document is not "loading", it is either "interactive" (document has finished loading, but sub-resources
    // such as scripts, images, stylesheets and frames are still loading) or "complete" (the page is fully loaded) and
    // we can immediately execute the callback.
    if (document.readyState !== 'loading') {
        return fn();
    }

    // Otherwise, wait until document is loaded
    document.addEventListener('DOMContentLoaded', fn, false);
};

/**
 * Dynamische Livestream-URL
 *
 * movingimage möchte sich vorbehalten, die finale URL des Livestreams ad hoc ändern zu können; wir haben vereinbart,
 * dass sie uns einen statischen JSON-Endpunkt anbieten, aus dem wir die URL beziehen können. Als Fallback kann im
 * Textbaustein "movingimage-livestream" auf dem Iframe die zu gegebenem Zeitpunkt bekannte URL eingetragen werden.
 */
class MovingimageLivestream {
    constructor(element) {
        this.iframe = element;
        this.jsonUrl = this.iframe.dataset.jsonUrl;

        if (this.jsonUrl) {
            this.fetchJson()
                .then(json => this.setIframeSrc(json.url))
                .catch(error => console.error(error));
        }
    }

    async fetchJson() {
        const response = await fetch(this.jsonUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        return json;
    }

    setIframeSrc(src) {
        this.iframe.src = src;
    }
}

documentReady(function() {
    const livestreamIframe = document.getElementById('movingimage-livestream');

    if (livestreamIframe) {
        new MovingimageLivestream(livestreamIframe);
    }
});
