$('body').bind('fx-ready', function(event) {
    $(event.target).find('.fx-sort-links').each(function() {
        var links = $(this).hide();

        var select = links.after('<select></select>').next();

        links.children('li').each(function() {
            var li = $(this);
            var option = select.append('<option>' +  li.children('a,span').text() + '</option>').children(':last');
            var link = li.find('> a')
            option.data('link', link);
            if (!link.length) {
                option.attr('selected', 'selected');
            }
        });

        select.change(function() {
            var select = $(this);
            var selectedOption = select.find('option:selected');

            if (selectedOption.data('link')) {
                if (selectedOption.data('link').triggerHandler('click') !== false) {
                    links.trigger({
                        type: 'fx-load-url',
                        url: selectedOption.data('link').attr('href')
                    });
                }
            }
        });
    });
});